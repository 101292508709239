import {
  AmazonIcon,
  AppleIcon,
  AppleMusicIcon,
  ApplePodcastIcon,
  AudibleIcon,
  BehanceIcon,
  BlueskyIcon,
  Bubble3Icon,
  ChainLink2Icon,
  DiscordIcon,
  DribbbleIcon,
  Email2Icon,
  FacebookIcon,
  FacebookMessengerIcon,
  GithubIcon,
  GooglePlayIcon,
  InstagramIcon,
  LineBrandIcon,
  LinkedinIcon,
  LinktreeIcon,
  LogoStandaloneIcon,
  OnlyfansIcon,
  PhoneIcon,
  PinterestIcon,
  SnapchatIcon,
  SoundcloudIcon,
  SpotifyIcon,
  TelegramIcon,
  ThreadsIcon,
  TiktokIcon,
  TwitchIcon,
  VimeoIcon,
  WechatIcon,
  WhatsappIcon,
  XTwitterIcon,
  YoutubeIcon,
} from '@fingertip/icons'
import { CSSProperties } from 'react'

import { SocialKeys } from '@/lib/data/socials'

type Props = {
  kind?: SocialKeys
  width?: number
  height?: number
  className?: string
  style?: CSSProperties
}

export const SocialIcon = ({ kind, ...props }: Props) => {
  switch (kind) {
    case 'apple-music':
      return <AppleMusicIcon {...props} />
    case 'apple-podcast':
      return <ApplePodcastIcon {...props} />
    case 'apple-app-store':
      return <AppleIcon {...props} />
    case 'discord':
      return <DiscordIcon {...props} />
    case 'email':
      return <Email2Icon {...props} />
    case 'phone':
      return <PhoneIcon {...props} />
    case 'facebook':
      return <FacebookIcon {...props} />
    case 'onlyfans':
      return <OnlyfansIcon {...props} />
    case 'google-play-store':
      return <GooglePlayIcon {...props} />
    case 'instagram':
      return <InstagramIcon {...props} />
    case 'linkedin':
      return <LinkedinIcon {...props} />
    case 'pinterest':
      return <PinterestIcon {...props} />
    case 'snapchat':
      return <SnapchatIcon {...props} />
    case 'soundcloud':
      return <SoundcloudIcon {...props} />
    case 'spotify':
      return <SpotifyIcon {...props} />
    case 'telegram':
      return <TelegramIcon {...props} />
    case 'tiktok':
      return <TiktokIcon {...props} />
    case 'twitch':
      return <TwitchIcon {...props} />
    case 'twitter':
      return <XTwitterIcon {...props} />
    case 'whatsapp':
      return <WhatsappIcon {...props} />
    case 'youtube':
      return <YoutubeIcon {...props} />
    case 'dribbble':
      return <DribbbleIcon {...props} />
    case 'github':
      return <GithubIcon {...props} />
    case 'facebook-messenger':
      return <FacebookMessengerIcon {...props} />
    case 'url':
      return <ChainLink2Icon {...props} />
    case 'audible':
      return <AudibleIcon {...props} />
    case 'amazon':
      return <AmazonIcon {...props} />
    case 'fingertip':
      return <LogoStandaloneIcon {...props} />
    case 'wechat':
      return <WechatIcon {...props} />
    case 'bluesky':
      return <BlueskyIcon {...props} />
    case 'behance':
      return <BehanceIcon {...props} />
    case 'linktree':
      return <LinktreeIcon {...props} />
    case 'threads':
      return <ThreadsIcon {...props} />
    case 'vimeo':
      return <VimeoIcon {...props} />
    case 'sms':
      return <Bubble3Icon {...props} />
    case 'line':
      return <LineBrandIcon {...props} />
    default:
      return null
  }
}
